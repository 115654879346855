import React from "react";
import { Link } from "gatsby";
import { Col, Container, Row } from "react-bootstrap";

import { Box, Button, List, Section, Text, Title } from "../components/Core";
import PageWrapper from "../components/PageWrapper";
import CTA from "../sections/common/CTA";
import imgWorkCover from "../assets/image/grofalex/planning-tool.png";
import isDriving from "../assets/image/grofalex/is-driving.png";
import img from "../assets/image/grofalex/img.png";
import img1  from "../assets/image/grofalex/img_1.png";
import img2  from "../assets/image/grofalex/img_2.png";
import img3  from "../assets/image/grofalex/img_3.png";
import img4  from "../assets/image/grofalex/img_4.png";
import img5 from "../assets/image/grofalex/img_5.png";
import img6 from "../assets/image/grofalex/img_6.png";
import img7 from "../assets/image/grofalex/img_7.png";

import { device } from "../utils";

const WorkSingle = () => {
  return (
    <>
      <PageWrapper>
        <Section hero>
          <Container>
            <Row className="justify-content-center">
              <Col lg="8">
                <Box>
                  <Text variant="tag">Ecology & Planning</Text>
                  <Title className="my-4 text-center">
                    Grofalex
                    <br />
                    A Planning tool for Ecological Projects
                  </Title>
                  <Text
                    variant="p"
                    css={`
                      max-width: 750px;
                    `}
                  >
                    For any project that involves building, demolishing or changing the landscape you will be needing a
                    permit of the local government. The permit requires you to proof
                    that no animals, like sparrows, bats or any protected animal live at the location you want to build
                    on. This is where Grofalex comes in. Grofalex arranges field workers to
                    survey the location and report back to Grofalex. Grofalex then uses the data to create a report that
                    can be used to apply for a permit (or keep a permit).
                    <br />
                    <br />
                    To be able to send out field workers, Grofalex needed a planning tool that could be used by the field workers, and that's where I came in.
                    The tool required managing multiple projects (organisations), consisting of multiple locations where multiple surveys needed to take place.
                    Furthermore, field workers would need to be assigned to the location and planned at a specific date. The tool also needed to be able to
                    store reports based on the data collected by the field workers.
                    <br />
                    <br />
                    After the job is done for the field workers, they also will need to get paid for their work. The tool required a feature to keep track of
                    the hours they've worked and the distance traveled to finalize the job. This involves calculating the route someone took to get to the
                    location and back. Lastly, within the tool needed to log the amount of hours someone worked based on the time they've started and
                    ended their shift was needed. At the end of the month, the worked hours are aggregated and available for export.
                    <br />
                    <br />
                    This tool was built using React, Node.js / Express and Postgres, running on a Linux server that runs Docker in combination with Dokku.
                    Dokku is an open source tool that gives you the same power as Heroku, but on your own server (therefore a fraction of the price). Meaning
                    you get auto deployments and rolling releases when you push to your deployment branches.
                    <br />
                    <br />
                    I love to use Docker to be able to switch between applications I'm working with, such that I don't mess up my own environment, and above all
                    making sure the application runs the same on my local machine as it does on the server.
                    <br />
                    <br />
                    The tool was built in a way that it could be extended with new features. The project was built by me alone in 3 months time. It's really the most
                    fun and challenging projects I've worked on! Due to the complexity, tight deadlines, I could bundle all my knowledge of the last 12 years into one project.
                    I'm super proud of the result and I'm happy it all worked out in the end.
                    <br />
                    <br/>
                    The amount of features I've built for this project is too much to list here, but I've listed some of the features below:
                    <List className="py-2">
                      <li>Managing multiple organisations.</li>
                      <li>Adding areas using and assigning activities do be done there.</li>
                      <li>Create activities of specific type</li>
                      <li>Activities with a specific type are required to be done within a certain timeslot.</li>
                      <li>Field workers need to be able to fill in their availability.</li>
                      <li>A planning screen where workers can be planned on a shift within a timeslot</li>
                      <li>Field workers need to see which shifts they are planned on and when and where to go.</li>
                      <li>They need to be able to submit reports</li>
                      <li>Reports can be approved / rejected</li>
                      <li>Resubmitting reports should be possible</li>
                      <li>Cancellations of shifts (because of outside factors) should be possible</li>
                      <li>Filter workers based on the distance to the area.</li>
                      <li>Filter on things like that they have a care or driving license.</li>
                      <li>Attach multiple field workers to a shift.</li>
                      <li>The planning needs to be pending until published, and only then are changes visible to users.</li>
                      <li>Aggregations of worked hours and travelled distances (by the most optimal route) per time-period</li>
                    </List>
                  </Text>

                  <Row>
                    <Col lg="8">
                      <Box>
                        <Text className="pt-4 pb-2" css={`font-weight: 800`} variant="h4">
                          Experiences gained here:
                        </Text>

                        <List>
                          <li>Deepening my knowledge of NodeJS & Express.</li>
                          <li>Postgresql + Postgis for measuring distances of workers to the area.</li>
                          <li>Working with Vercel</li>
                          <li>Used my Laravel knowledge and using them within Express.</li>
                          <li>Getting to know ReactJS in a more intricate way than before.</li>
                          <li>Using Dokku in combination with Docker.</li>
                          <li>Using Prisma ORM</li>
                          <li>Using Mapbox</li>
                          <li>Deepening my knowledge of Typescript</li>
                        </List>

                        <Text className="py-2" variant="h5">
                          Any further questions? I'd love to show it to you!
                        </Text>
                      </Box>
                    </Col>
                  </Row>
                </Box>
              </Col>
            </Row>

            <Box pt={["2rem", null, null, "6.25rem"]}>
              <Text className="pt-4 pb-2" css={`font-weight: 800`} variant="h4">
                Here a sneak peak of the tool itself, here you see some screens of the tool.
              </Text>
              <img src={imgWorkCover} alt="" className="img-fluid w-100" />
              <img src={isDriving} alt="" className="img-fluid w-100" />
              <img src={img2} alt="" className="img-fluid w-100" />
              <img src={img1} alt="" className="img-fluid w-100" />
              <img src={img5} alt="" className="img-fluid w-100" />
              <img src={img4} alt="" className="img-fluid w-100" />
              <img src={img3} alt="" className="img-fluid w-100" />
              <img src={img6} alt="" className="img-fluid w-100" />
              <img src={img7} alt="" className="img-fluid w-100" />
            </Box>
          </Container>
        </Section>

        <div className="mt-lg-3">
          <Container>
            <Row>
              <Col lg="4" className="mb-2 mb-lg-0">
                <Text variant="tag">Time active</Text>
                <Title variant="cardBig" className="mt-3">
                  January 2023 - March 2023
                </Title>
              </Col>
              <Col lg="4" className="d-flex justify-content-lg-end">
                <a href="https://grofalex.nl" target="_blank">
                  <Button arrowRight>Grofalex</Button>
                </a>
              </Col>
            </Row>
          </Container>
        </div>
        <Section className="pt-0">
          <Container>
            <div className="text-center mt-lg-5">
              <Text variant="tag" className="mb-1" color="lightShade">
                Next Project
              </Text>
              <Link to="portfolio-details">
                <Button
                  arrowRight
                  className="border-0 bg-transparent shadow-none text-capitalize py-3"
                  css={`
                    font-weight: 700
                    font-size: 1.5rem;
                    letter-spacing: -1.2px;
                    line-height: 1.375;
                    @media ${device.md} {
                      font-size: 2rem;
                    }
                `}
                >
                  Replace
                </Button>
              </Link>
            </div>
          </Container>
        </Section>
        <Box py={4}>
          <CTA />
        </Box>
      </PageWrapper>
    </>
  );
};
export default WorkSingle;
